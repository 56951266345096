import React from 'react';
import CustomText from './CustomText';
import { ThemeProvider } from './ThemeContext';
import Footer from './Footer';
import Navbar from './Navbar';

const App = () => {
  return (
    <ThemeProvider>
      <div style={styles.container}>
        <Navbar />
        <div style={styles.content}>
          <CustomText size="massive" weight="bold" fontFamily="Playfair" style={styles.heading}>
            Aurelius Applications Inc.
          </CustomText>
          <CustomText size="regular" weight="regular" fontFamily="Poppins" style={styles.description}>
            Aurelius Applications Inc. embraces the philosophy of stoicism. Life is all about the choices we make. It is our responsibility, and within our power, to improve our lives and become the people we want to be. In other words, Stoicism is about personal accountability.
            <br /><br />
            We seek to build products for the martial arts community. As our first project, we have built a companion app for Brazilian Jiu-Jitsu (BJJ). We call it <a target='_blank' rel="noreferrer" href="https://www.thekimura.app/" style={styles.link}>Kimura</a>.
          </CustomText>
          <div style={styles.imageContainer}>
            <img src={`${process.env.PUBLIC_URL}/kimura-logo-web.png`} alt="Company related" style={styles.image} />
          </div>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Ensure content starts at the top
    alignItems: 'center',
    paddingTop: '60px', // Ensure content is below the navbar
    paddingBottom: '60px', // Ensure content is above the footer
    flex: 1,
    maxWidth: '600px',
    width: '100%',
    padding: '0 20px',
    boxSizing: 'border-box', // Include padding in the width calculation
    margin: '80px auto',
    overflowY: 'auto', // Enable vertical scrolling
  },
  heading: {
    display: 'block',
    marginBottom: '20px',
  },
  description: {
    marginBottom: '40px', // Ensuring margin below the description
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '200px',
    width: '80%',
    height: 'auto',
  },
};

export default App;
