import React from 'react';
import CustomText from './CustomText';

const Navbar = () => {
    return (
        <nav style={styles.navbar}>
            <CustomText size="medium" weight="regular" fontFamily="Poppins" style={styles.navItem}>
                <a target='_blank' rel="noreferrer" href="/" style={styles.link}>Home</a>
            </CustomText>
            <CustomText size="medium" weight="regular" fontFamily="Poppins" style={styles.navItem}>
                <a target='_blank' rel="noreferrer" href="https://www.thekimura.app/about-us" style={styles.link}>About Us</a>
            </CustomText>
        </nav>
    );
};

const styles = {
    navbar: {
        display: 'flex',
        justifyContent: 'center',
        padding: '10px',
        borderBottom: '1px solid #ddd',
        position: 'fixed',
        top: 0,
        width: '100%',
        backgroundColor: '#fff',
        zIndex: 1000,
    },
    navItem: {
        margin: '0 15px',
    },
};

export default Navbar;
