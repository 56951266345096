import React from 'react';
import CustomText from './CustomText';

const Footer = () => {
    return (
        <div style={styles.footer}>
            <CustomText size="small" weight="regular" fontFamily="Poppins">
                <a target='_blank' rel="noreferrer" href="https://www.thekimura.app/privacy-policy" style={styles.link}>Privacy Policy</a>
                <span style={styles.separator}>|</span>
                <a target='_blank' rel="noreferrer" href="https://www.thekimura.app/terms-and-conditions" style={styles.link}>Terms of Use</a>
            </CustomText>
        </div>
    );
};

const styles = {
    footer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '10px',
        borderTop: '1px solid #ddd',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#fff',
        zIndex: 1000,
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
        margin: '0 10px',
    },
    separator: {
        margin: '0 10px',
    },
};

export default Footer;
