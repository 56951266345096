import React, { createContext, useState } from 'react';

export const ThemeContext = createContext();

const defaultTheme = {
    mode: 'light',
    backgroundPrimary: '#FBF6F0',
    backgroundSecondary: '#FFFFFF',
    fonts: {
        families: {
            Poppins: 'Poppins, sans-serif',
            Playfair: 'Playfair Display, serif',
        },
        weights: {
            regular: 400,
            medium: 500,
            bold: 700,
            semiBold: 600,
            light: 300,
        },
        sizes: {
            tiny: '10px',
            small: '12px',
            regular: '14px',
            medium: '16px',
            large: '18px',
            xlarge: '20px',
            massive: '24px',
        },
    },
};

export const ThemeProvider = ({ children }) => {
    const [theme] = useState(defaultTheme);

    return (
        <ThemeContext.Provider value={{ theme }}>
            {children}
        </ThemeContext.Provider>
    );
};
