import React, { useContext } from 'react';
import { ThemeContext } from './ThemeContext';

const CustomText = ({ style, size = 'medium', weight = 'regular', fontFamily = 'Poppins', children, ...props }) => {
    const { theme } = useContext(ThemeContext);

    const getFontStyle = () => {
        return {
            fontFamily: theme.fonts.families[fontFamily] || theme.fonts.families.Poppins,
            fontWeight: theme.fonts.weights[weight],
            fontSize: theme.fonts.sizes[size],
        };
    };

    return (
        <span style={{ ...getFontStyle(), ...style }} {...props}>
            {children}
        </span>
    );
};

export default CustomText;
